<ion-header>
    <ion-toolbar color="gallaghergreen">
      <img (click)="navigateToBaseLogin()" class="gallagher-logo" [src]="'./assets/images/Gallagher Logo -White Transparent.png'" />
    </ion-toolbar>
</ion-header>
  
<ion-content class="login-content">

  <div (click)="navigateToBaseLogin()" class="back-button-mobile">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </div>

  <div class="parent-container">
    <ion-card [ngClass]="{'larger-width': authService.isNative}">
        <ion-card-header>
            <div>
                <span class="login-title">Create an Account</span>
            </div>
            <div>
                <span>Check your garment label for the following information</span>
            </div>
        </ion-card-header>
        <ion-grid>
          <div *ngIf="currentStep === STEP.CREATE">
            <ion-row>
              <ion-col>
                <ion-label>Stop #</ion-label>
                <ion-input type="text" [(ngModel)]="stopCode" placeholder="Enter your stop #" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>Locker #</ion-label>
                <ion-input type="text" [(ngModel)]="lockerNumber" placeholder="Enter your locker #" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>Last Name</ion-label>
                <ion-input type="text" [(ngModel)]="lastName" placeholder="Enter your last name" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
          </div>

          <div *ngIf="currentStep === STEP.DATA">
            <ion-row>
              <ion-col>
                <ion-label>Email *</ion-label>
                <ion-input type="text" [(ngModel)]="email" placeholder="Enter your email address" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>Password *</ion-label>
                <ion-input type="password" [(ngModel)]="password" placeholder="Enter your password" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>Confirm Password *</ion-label>
                <ion-input type="password" [(ngModel)]="confirmationPassword" placeholder="Re-enter your password" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>Cellphone Number #</ion-label>
                <ion-input type="text" [(ngModel)]="cellphoneNumber" placeholder="Optional for text messages" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
          </div>

          <ion-row>
            <ion-col *ngIf="(currentStep === STEP.DATA)">
                <ion-button color="gallaghergreen" class="btn-box-shadow submit-button" (click)="back()">Back</ion-button>
            </ion-col>
            <ion-col>
                <ion-button color="gallaghergreen" class="btn-box-shadow submit-button" 
                [disabled]="((currentStep === STEP.CREATE) && (!lastName || !lockerNumber || !stopCode)) || ((currentStep === STEP.DATA) && (!email || !password || !confirmationPassword))" (click)="continue()">
                {{(currentStep === STEP.CREATE) ? 'Next' : 'Submit'}}
                </ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="garment-label-button">
            <ion-col></ion-col>
            <ion-col (click)="openWhyRegisterPopup()" class="register-text" size="auto">
              <span>(Why Register?)</span>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
  </div>
</ion-content>