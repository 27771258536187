<ion-content class="invoice">
    <ng-container>
        <app-header [isParentPage]="true" [title]="'Invoice'"></app-header>
        <ion-grid class="container-grid">
            <ion-row class="filter-row">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <ion-input type="date" [(ngModel)]="startDate" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-input type="date" [(ngModel)]="endDate" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-input type="text" [(ngModel)]="customerCode" placeholder="Enter customer number" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-button (click)="getInvoices()" color="gallaghergreen" class="btn-box-shadow btn-margin"><span>Show</span></ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>
            <ion-row class="spinner-container" *ngIf="loading">
                <ion-col>
                    <ion-spinner class="spinner" name="circular"></ion-spinner>
                </ion-col>
            </ion-row>
            <ion-row class="invoice-table-row" *ngIf="submitted && !loading">
                <div>
                    <ion-row>
                        <ion-header>Past Due Invoices</ion-header>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <app-invoice-table (paging)="setOverduePage($event)" [pageSize]="pageSize" [pageNumber]="overdueCurrPage" [rows]="overdueInvoices" [totalElements]="overdueTotalElements" [currentRoute]="currentRoute"></app-invoice-table>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-header class="invoice-header">Invoices from {{startDate | date: 'MM/dd/yyyy' ?? 'MM/dd/yyyy'}} to {{endDate | date: 'MM/dd/yyyy' ?? 'MM/dd/yyyy'}}</ion-header>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <app-invoice-table (paging)="setInvoicesPage($event)" [pageSize]="pageSize" [pageNumber]="invoiceCurrPage" [rows]="invoices" [totalElements]="invoiceTotalElements" [currentRoute]="currentRoute"></app-invoice-table>
                        </ion-col>
                    </ion-row>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>