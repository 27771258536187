// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: true,
  // apiUrl: 'http://gtrakstaging.gallagheruniform.com:8008',
  // apiUrl: 'http://localhost:9200',
  //apiUrl: 'https://44e7-50-219-161-162.ngrok-free.app',
  apiUrl: 'http://64.136.239.133:8008', // Production
  //apiUrl: 'http://64.136.239.133:8009', // Staging
  enableFunctionsEmulator: false,
  firebase: {
    apiKey: 'AIzaSyB5rNf7pWgBfNRB1lz_13FEX4VaNWOiklg',
    authDomain: 'gallagher-gtrack.firebaseapp.com',
    projectId: 'gallagher-gtrack',
    storageBucket: 'gallagher-gtrack.appspot.com',
    messagingSenderId: '327250568154',
    appId: '1:327250568154:web:ec72b987cc659bdd4616e3',
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500,
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
