<ion-content class="invoice" scroll="false">
    <ng-container *ngIf="loaded">
        <app-header [title]="'Invoice Details'"></app-header>
        <div class="invoice-container">
            <div class="top-header-row">
                <div class="image-container">
                    <img src="../../../../assets/images/gallagher logo_h.png">
                </div>
                <div class="titles">
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-number-text">INVOICE #: </span>
                        </div>
                        <div>
                            <span class="invoice-number-text">{{invoiceDetails.invoiceCode}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Date:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.dateOfInvoice | date: "MM/dd/yyyy"}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Customer #:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.customer?.customerCode ?? 'Unknown'}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Stop #:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.stopCode}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Purchase Order #:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.poNum}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <ion-grid>
                <ion-row class="cust-info">
                    <ion-col>
                        <ion-row class="cust-info-1">
                            <ion-col size="auto" class="ion-no-padding cust-info-header-col">
                                <ion-row class="cust-title">
                                    <div>Invoice To:</div>
                                </ion-row>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <ion-row class="cust-gen-title">
                                    {{invoiceDetails.address.billingName}}
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.billingAddress1}} 
                                </ion-row>
                                <ion-row *ngIf="invoiceDetails.address.billingAddress2" class="cust-gen">
                                    {{invoiceDetails.address.billingAddress2}} 
                                </ion-row>
                                <ion-row *ngIf="invoiceDetails.address.billingAddress3" class="cust-gen">
                                    {{invoiceDetails.address.billingAddress3}} 
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.billingCity}}, {{invoiceDetails.address.billingState}} {{invoiceDetails.address.billingPostalCode}}
                                </ion-row>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col>
                        <ion-row class="cust-info-2">
                            <ion-col size="auto" class="ion-no-padding cust-info-header-col">
                                <ion-row class="cust-title">
                                    <div>Deliver To:</div>
                                </ion-row>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <ion-row class="cust-gen-title">
                                    {{invoiceDetails.address.deliveryName}}
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.deliveryAddress1}}
                                    <ng-container *ngIf="invoiceDetails.address.deliveryAddress2">
                                        {{invoiceDetails.address.deliveryAddress2}}  
                                    </ng-container>
                                    <ng-container *ngIf="invoiceDetails.address.deliveryAddress3">
                                        {{invoiceDetails.address.deliveryAddress3}}  
                                    </ng-container>
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.deliveryCity}}, {{invoiceDetails.address.deliveryState}} {{invoiceDetails.address.deliveryPostalCode}}
                                </ion-row>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid>
                <ion-row class="top-row">
                    <ion-col class="double-b" style="text-align: center;">
                        <ion-header class="ion-no-border">Week</ion-header>
                        {{invoiceDetails.routeWeek}}
                    </ion-col>
                    <ion-col style="text-align: center;">
                        <ion-header class="ion-no-border ">Day</ion-header>
                        {{invoiceDetails.routeDay}}
                    </ion-col>
                    <ion-col style="text-align: center;">
                        <ion-header class="ion-no-border">Route</ion-header>
                        {{invoiceDetails.routeCode}}
                    </ion-col>
                </ion-row>
                <ion-row class="main-row">
                    <ion-col size="1" class="double-b" >
                        <ion-header class="ion-no-border mobile-header">Line Num</ion-header>
                        <ion-row *ngFor="let key of newKeys" style="justify-content: center;">{{key}}</ion-row>
                    </ion-col>
                    <ion-col size="1">
                        <ion-header class="ion-no-border mobile-header">Wash Qty</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems" style="justify-content: center;">{{item.washQty}}</ion-row>
                    </ion-col>
                    <ion-col size="0.9">
                        <ion-header class="ion-no-border mobile-header">Emp ID</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems" style="justify-content: center;">{{item.employeeCode}}</ion-row>
                    </ion-col>
                    <ion-col size="2.9">
                        <ion-header class="ion-no-border mobile-header">Item or Emp Name</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems">{{item.itemName}}</ion-row>
                    </ion-col>
                    <ion-col size="2">
                        <ion-header class="ion-no-border mobile-header">Description</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems">{{item.itemCode}}</ion-row>
                    </ion-col>
                    <ion-col size="1">
                        <ion-header class="ion-no-border mobile-header">Inv. Qty</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems" style="justify-content: center;">{{item.issueQty}}</ion-row>
                    </ion-col>
                    <ion-col size="1">
                        <ion-header class="ion-no-border mobile-header">Chg Qty</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems" style="justify-content: center;">{{item.chargeQty}}</ion-row>
                    </ion-col>
                    <ion-col size="1.2">
                        <ion-header class="ion-no-border mobile-header">Unit Price</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems" style="justify-content: center;">{{item.chargeAmt | currency: 'USD'}}</ion-row>
                    </ion-col>
                    <ion-col size="1">
                        <ion-header class="ion-no-border mobile-header">Bill Amt</ion-header>
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems" style="justify-content: center;">{{item.billingAmount | currency: 'USD'}}</ion-row>
                    </ion-col>
                </ion-row>
                <ion-row class="total-row">
                    <ion-col size="9" class="thank-you">
                        <div style="text-align: center;">
                            Thank you for letting us serve you!
                        </div>
                    </ion-col>
                    <ion-col class="total-col" style="text-align: center;">
                        <ion-col>
                            <ion-col>
                                <ion-header class="ion-no-border" class="print-border">Subtotal</ion-header>
                                {{subTotal | currency: 'USD'}}
                            </ion-col>
                            <ion-col>
                                <ion-header class="ion-no-border" style="border-top: 1px solid black">Tax</ion-header>
                                {{invoiceDetails.tax1Amount | currency: 'USD'}}
                            </ion-col>
                        </ion-col>
                    </ion-col>
                </ion-row>
                <ion-row class="sig-row">
                    <ion-col class="double-b" >
                        <ion-header class="ion-no-border">Customer Signature</ion-header>
                        <p>X</p>
                        <hr class="signature-line">
                    </ion-col>
                    <ion-col style="text-align: center;">
                        <ion-header class="ion-no-border">Terms</ion-header>
                        <div style="padding-top: 0.5rem;">{{invoiceDetails.termName}}</div>
                    </ion-col>
                    <ion-col style="text-align: center;">
                        <ion-header class="ion-no-border">Due Date</ion-header>
                        <div style="padding-top: 0.5rem;">{{invoiceDetails.dueDate | date: "MM/dd/yyyy"}}</div>
                    </ion-col>
                    <ion-col style="text-align: center;">
                        <ion-header class="ion-no-border">Invoice Total</ion-header>
                        <div style="padding-top: 0.5rem;">{{invoiceDetails.amountDue | currency: 'USD'}}</div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <button></button>
        </div>

    </ng-container>
</ion-content>