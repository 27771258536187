import { Component, OnInit } from '@angular/core';
import { InvoiceRoutes } from '../../invoice.routes';
import { ViewWillEnter } from '@ionic/angular';
import { Router } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { InvoiceService } from '../../services/invoice.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { isThisSecond } from 'date-fns';
import { forkJoin } from 'rxjs';
import { ToasterService } from '../../../../../app/common/services/toaster.service';

const testData = require('../../../../common/test-data/invoice-data.json');

@Component({
  selector: 'app-invoice-page',
  templateUrl: 'invoice.page.html',
  styleUrls: [
    './invoice.page.scss'

  ]
})
export class InvoicePage implements OnInit, ViewWillEnter {

  customerCode: string;
  startDate: Date;
  endDate: Date;

  public overdueInvoices: any;
  public invoices: any;

  currentRoute: string;

  overdueParams: any;
  invoiceParams: any;

  overdueTotalPages: number = 0;
  overdueTotalElements: number = 0;

  invoiceTotalPages: number = 0;
  invoiceTotalElements: number = 0;

  overdueCurrPage: number;
  invoiceCurrPage: number;
  pageSize: number;

  submitted = false;

  constructor(
    private router: Router,
    private authService: GtrakAuthService,
    private invoiceService: InvoiceService,
    private toasterService: ToasterService
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {

    this.overdueCurrPage = 1;
    this.invoiceCurrPage = 1;
    this.pageSize = 200;

    this.overdueParams = new RequestParams();
    this.invoiceParams = new RequestParams();

    this.overdueParams.setPaging(this.overdueCurrPage, this.pageSize);
    this.invoiceParams.setPaging(this.invoiceCurrPage, this.pageSize);

    if(!this.authService.user.canViewInvoice) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    this.customerCode = this.authService.user.customerCode;

    this.setDefaults();

    this.invoices = [];
    this.overdueInvoices = [];
    this.currentRoute = this.router.url;
  }

  ionViewWillEnter(): void {
    //this.invoices = testData;
    //this.overdueInvoices = testData;
  }

  setDefaults(): void {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substring(0, 10) as any;
    this.endDate = new Date().toISOString().substring(0, 10) as any;
  }

  async getInvoices(): Promise<void> {
    if (this.authService.user.customerCode?.length > 0 && this.customerCode !== this.authService.user.customerCode) {
      this.toasterService.presentToast('danger', '', 'Invalid Customer Code', 1600);
      this.customerCode = this.authService.user.customerCode;
      return;
    }

    if(this.endDate && this.startDate && this.customerCode && this.customerCode != '') {
      this.submitted = true;

      this.overdueCurrPage = 1;
      this.invoiceCurrPage = 1;
      
      await this.getOverdueInvoices();
      await this.getInvoicesInRange();
    } else {
      this.toasterService.presentToast('danger', '', 'Please fill out all fields', 1600);
    }

  }

  async getOverdueInvoices(): Promise<any> {
    this.overdueParams.setPaging(this.overdueCurrPage, this.pageSize);
    this.overdueParams.filters.overdue = true;
    this.overdueParams.filters.customerCode = this.customerCode;
    this.overdueParams.setOrdering('DueDate', 'asc');

    const overdueInv: any = await this.invoiceService.getInvoices(this.overdueParams);

    this.overdueTotalElements = overdueInv.totalRecords;
    this.overdueTotalPages = overdueInv.totalPages;
    this.overdueInvoices = overdueInv.results;

    return overdueInv;
  }

  async getInvoicesInRange(): Promise<any> {
    this.invoiceParams.setPaging(this.invoiceCurrPage, this.pageSize);
    //this.invoiceParams.filters.excludeOverdue = true;
    this.invoiceParams.filters.customerCode = this.customerCode;
    this.invoiceParams.setOrdering('DateOfInvoice', 'desc');
    this.invoiceParams.filters.dateRange = this.startDate.toString() + ',' +  this.endDate.toString();

    const invoiceResults: any = await this.invoiceService.getInvoices(this.invoiceParams);

    this.invoiceTotalElements = invoiceResults.totalRecords;
    this.invoiceTotalPages = invoiceResults.totalPages;
    this.invoices = invoiceResults.results;

    return invoiceResults;
  }

  setOverduePage(page: number): void {
    this.overdueCurrPage = page + 1;
    this.getOverdueInvoices();
  }

  setInvoicesPage(page: number): void {
    this.invoiceCurrPage = page + 1;
    this.getInvoicesInRange();
  }


}