<ion-header>
    <ion-toolbar color="gallaghergreen">
      <img (click)="navigateToBaseLogin()" class="gallagher-logo" [src]="'./assets/images/Gallagher Logo -White Transparent.png'" />
    </ion-toolbar>
</ion-header>
  
<ion-content class="login-content">

  <div (click)="navigateToBaseLogin()" class="back-button-mobile">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </div>

  <div class="parent-container">
    <ion-card [ngClass]="{'larger-width': authService.isNative}">
        <ion-card-header>
            <div>
                <span class="login-title">Forgot Password</span>
            </div>
        </ion-card-header>
        <ion-grid>
          <div>
            <ion-row>
              <ion-col>
                <ion-label>Email *</ion-label>
                <ion-input type="text" [(ngModel)]="email" placeholder="Enter your email" class="ion-text-left color-light user-id"></ion-input>
              </ion-col>
            </ion-row>
          </div>

          <ion-row>
            <ion-col>
                <ion-button color="gallaghergreen" class="btn-box-shadow submit-button" [disabled]="!email" (click)="submit()">Submit</ion-button>
            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-card>
  </div>
</ion-content>