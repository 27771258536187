import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../../../common/services/toaster.service';
import { GRouteLogService } from '../../../../../../features/g-route/services/g-route-log.service';
import { GtrakAuthService } from '../../../../../../features/authentication/services/gtrak-auth.service';
import { IRouteLogModel } from '../../../../../../features/g-route/models/route-log.model';
import { RouteDeliveryDetailsService } from '../../../../../../features/g-route/services/route-delivery-details.service';
import { MailService } from '../../../../../../features/g-route/services/mail.service';
import { LogType } from '../../../route-page/route.page';
import { ViewWillEnter } from '@ionic/angular';
import { RouteModel } from '../../../../../../../app/features/g-route/models/route.model';
import { GRouteService } from '../../../../../../../app/features/g-route/services/g-route.service';

@Component({
  selector: 'app-check-out-page',
  templateUrl: 'check-out.page.html',
  styleUrls: [
     './check-out.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class CheckOutPage implements OnInit, ViewWillEnter {

    logType = LogType;

    currentRoute: string;
    gRoute: RouteModel = null;
    stopName: string;
    cancelStatus: string;
    garmentStatus: string;
    emailStatus: string;

    messageCode: string;
    garmentCode: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toasterService: ToasterService,
        private routeLogService: GRouteLogService,
        public authService: GtrakAuthService,
        private routeDeliveryDetailsService: RouteDeliveryDetailsService,
        private mailService: MailService,
        private gRouteService: GRouteService
    ) { 
        this.currentRoute = null;
    }

    ngOnInit(): void {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');
    }

    ionViewWillEnter(): void {
        this.checkStatus();
    }

    async completeCheckOut(): Promise<void> {
        //check if any status is Todo
        if(this.cancelStatus === "Todo" || this.garmentStatus === "Todo" || this.emailStatus === "Todo") {
            this.toasterService.presentToast('danger', '', 'Ensure all items are complete', 1000);
            return;
        }

        const log: IRouteLogModel = {
            logType: this.logType.CHECK_OUT,
            routeCode: this.authService.user.routeCode,
            stopCode: this.stopName,
            odometer: 0,
            time: new Date(),
            driverCode: (this.authService.user.id as string)
        };

        const response = await this.routeLogService.createRouteLog(log);

        if (response) {
            this.toasterService.presentToast('primary', '', 'Check out complete', 2000);
            this.router.navigate([`/route/logsheet/stop/${this.stopName}`]);
        } else {
            this.toasterService.presentToast('danger', '', 'An error occurred processing check out request', 2000);
        }
    }

    async checkStatus(): Promise<void> {
        const today = new Date();
        //check cancel status
        this.routeLogService.checkCancel(this.authService.user.routeCode, this.stopName, this.authService.user.id as string, today).then((cancels) => {
            this.routeLogService.hasCancels(this.stopName).then((hasCancels) => {
                if(hasCancels.length === 0 && cancels.length === 0) {
                    const log: IRouteLogModel = {
                        routeCode: this.authService.user.routeCode,
                        stopCode: this.stopName,
                        driverCode: (this.authService.user.id as string),
                        logType: 5,
                        time: today,
                        comment: "Cancel Check N/A"
                    }
                    this.routeLogService.logCancel(log);
                    this.cancelStatus = "N/A";
                    return;
                }
                if(cancels.length > 0) {
                    this.cancelStatus = "Complete";
                } else {
                    this.cancelStatus = "Todo";
                }
            });
        });

        //get garment status
        this.gRoute = await this.gRouteService.get(
            this.authService.user.routeCode
        );
      
        const frequencyDate = this.gRoute.frequencyOffset(
            this.authService.user.routeDate
        );
        
        this.routeDeliveryDetailsService.GetPickupList(this.stopName, this.authService.user.routeCode, today, frequencyDate).then((data) => {
            if(data.length === 0) {
                this.garmentStatus = "N/A";
            }
            else {
                //loop over the items and check if not numeric
                let hasNonNumeric = false;
                data.forEach((item) => {
                    if(isNaN(+item.qtyAsString)) {
                        hasNonNumeric = true;
                    }
                });

                if(hasNonNumeric) {
                    this.garmentStatus = "Todo";
                }
                else {
                    this.garmentStatus = "Complete";
                }
            }
        });

        //get email status
        this.mailService.getMailStatus(this.stopName).then((status) => {
            if(status.numContacts === 0) {
                this.emailStatus = "N/A";
            } else if(status.mailSent === 0) {
                this.emailStatus = "Todo";
            } else {
                this.emailStatus = "Complete";
            }
        });
    }

    cancelLink(): void {    
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/cancel-report`]);
    }

    garmentLink(): void {
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/non-garments`]);
    }

    emailLink(): void {
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/email-report`]);
    }



}