import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { InvoiceService } from '../../services/invoice-details.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { ToasterService } from '../../../../../app/common/services/toaster.service';

@Component({
  selector: 'app-invoice-details-page',
  templateUrl: 'invoice-details.page.html',
  styleUrls: [
    './invoice-details.page.scss',
  ]
})
export class InvoiceDetailsPage implements ViewWillEnter, OnInit {

  public invoiceNumber: string;

  rows: any;
  currentRoute: string;

  invoiceParams: any

  public invoiceDetails: any
  public keysOfList: any;
  public newKeys: any;

  public subTotal: any;

  public loaded: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private toasterService: ToasterService,
  ) { 
    this.currentRoute = null;
  }

  async ngOnInit() {
    this.rows = [];
    this.currentRoute = this.router.url;

    this.invoiceParams = new RequestParams()

    this.invoiceParams.setPaging(1, 1)

    this.invoiceDetails = []

    this.invoiceNumber = this.route.snapshot.paramMap.get('invoice');


    await this.getInvoiceDetails(this.invoiceNumber)

    //transforming keys
    this.keysOfList = Object.keys(this.invoiceDetails.invoiceItems)
    this.newKeys = this.keysOfList.map(element => {
      var int = parseInt(element)
      int += 1
      element = int
      return element
    })


    //calculating total
    this.subTotal = 0
    this.invoiceDetails.invoiceItems.forEach(element => {
      this.subTotal += element.billingAmount
    });

  }

  ionViewWillEnter(): void {

  }


  async getInvoiceDetails(invoiceNum: any) {
    if(this.invoiceNumber !== ''){
      this.invoiceParams.filters.invoiceNumber = invoiceNum
      
      this.invoiceDetails = await this.invoiceService.getInvoiceDetails(this.invoiceParams, this.invoiceNumber);
      console.log(this.invoiceDetails);
    } else {
      this.toasterService.presentToast('danger','','Error finding invoice number',1600)
    }
    this.loaded = true;
  }
}