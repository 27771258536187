import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { GarmentSummaryService } from "../../../../features/garment-listing/services/garment-summary.service";
import { GarmentDetailService } from '../../../garment-listing/services/garment-detail.service';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { NavController } from '@ionic/angular';
import { GarmentRepairService } from '../../../../../app/features/g-route/services/garment-repair.service';
import { CreateWorkOrderService } from '../../../../../app/features/g-route/services/create-work-order.service';
import { EmailService } from '../../../../../app/common/services/email.service';
import { SMSService } from '../../../../../app/common/services/sms.service';

@Component({
  selector: 'app-repair-replace-page',
  templateUrl: 'repair-replace.page.html',
  styleUrls: [
    './repair-replace.page.scss'
  ]
})
export class RepairReplacePage implements ViewWillEnter, OnInit {

  reasonMap: Map<string, string> = new Map<string, string>([
    ['06', 'Relable Garment ID'],
    ['15', 'Repair - Belt Loop / Drawstring'],
    ['11', 'Repair - Buttons, Button Holes, Snaps'],
    ['13', 'Repair - Check All'],
    ['MSG0010064', 'Repair - Check Seat / Inner Thigh'],
    ['MSG0010031', 'Repair - Check Zipper'],
    ['21', 'Repair - Emblems'],
    ['14', 'Repair - Pockets'],
    ['12', 'Repair - Seams or Hems'],
    ['MSG0010070', 'Repair - Sleeve/Cuff'],
    ['MSG0010042', 'Repair - Tear'],
    ['MSG0010080', 'Replace - Inspect for Replacement'],
    ['05', 'Send to Customer Service'],
    ['MSG0010033', 'Verify - All Emblems'],
    ['MSG0010046', 'Verify - Name Emblem Spelling'],
  ]);

  employeeId: string;
  customerId: string;
  departmentId: string;

  garmentSummary: any;
  garments: any;
  loaded: boolean = false;
  empKey: any;

  employeeName: string = null;

  userEmail: string = null;

  currentDate: Date = null;

  selectedGarments: any[] = [];
  stepNumber: number = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    public summaryService: GarmentSummaryService,
    public detailService: GarmentDetailService,
    private toasterService: ToasterService,
    private navController: NavController,
    private garmentRepairService: GarmentRepairService,
    private createWorkOrderService: CreateWorkOrderService,
    private emailService: EmailService,
    private smsService: SMSService
  ) { 
  
  }

  async ngOnInit() {
    this.loaded = false;

    const promises: any[] = [];

    this.empKey = {
      CustomerCode: this.authService.user.claims.cus_code,
      DepartmentCode: this.authService.user.claims.dep_code,
      EmployeeCode: this.authService.user.claims.emp_code,
    };

    promises.push(this.summaryService.getForEmployee(this.empKey));
    promises.push(this.detailService.getForEmployee(this.empKey));

    const data = await Promise.all(promises);

    this.garmentSummary = data[0];

    this.employeeName = `${this.garmentSummary?.employeeListing?.firstName} ${this.garmentSummary?.employeeListing?.lastName}`;

    const garmentData = data[1];
    this.garments = Object.keys(garmentData).map(key => (garmentData[key]));

    this.garments.forEach(garment => {
      garment.checked = false;
      garment.expanded = false;
      garment.reason = '';
    });

    this.currentDate = new Date();
    this.userEmail = this.authService?.user?.id as string;

    this.loaded = true;
  }


  ionViewWillEnter(): void {


  }

  navigateBack(): void {
    this.navController.back();
  }

  checkAll(event) {
    //if checked true, add all garments to selectedGarments and set checked to true
    if (event.target.checked) {
      this.garments.forEach(garment => {
        garment.checked = true;
        this.selectedGarments.push(garment);
      });
    }
    //if checked false, remove all garments from selectedGarments and set checked to false
    else {
      this.garments.forEach(garment => {
        garment.checked = false;
        this.selectedGarments = [];
      });
    }
  }

  checkAllSelected(event) {
    //if checked true, add all selectedGarments to selectedGarments and set checked to true
    if (event.target.checked) {
      this.selectedGarments.forEach(garment => {
        garment.checked = true;
        //check if garment is already in selectedGarments
        if (!this.selectedGarments.includes(garment)) {
          this.selectedGarments.push(garment);
        }
      });
    }
    //if checked false, remove all selectedGarments from selectedGarments and set checked to false
    else {
      this.selectedGarments.forEach(garment => {
        garment.checked = false;
        this.selectedGarments = [];
      });
    }
  }

  nextStep() {
    if(this.stepNumber === 1){
      if(this.selectedGarments.length === 0){
        this.toasterService.presentToast('danger', '', 'Please select at least one garment to repair or replace.', 2000);
        return;
      }
    }
    if(this.stepNumber === 2){
      if(this.selectedGarments.length === 0){
        this.toasterService.presentToast('danger', '', 'Please select at least one garment to repair or replace.', 2000);
        return;
      }
    }
    this.stepNumber++;
  }

  prevStep() {
    this.stepNumber--;
  }

  async submit() {
    const promises: any[] = [];

    try {
      const messages = [];
      // this.selectedGarments.forEach(async s => {
      //   promises.push(this.garmentRepairService.postMessage(s.reason, s.garmentCode));
      // });

      // Might improve this is the future. Calling concurrently causes issue with key generation
      for (var i = 0; i < this.selectedGarments.length; i++) {
        const data = await this.garmentRepairService.postMessage(this.selectedGarments[i].reason, this.selectedGarments[i].garmentCode);
        messages.push(data);
      }

      this.toasterService.presentToast('primary', 'Thank you for your order! Your request has been submitted successfully.', 'Request Confirmed', 4000);
      this.navController.navigateRoot(['/mobile']);

      // const repairReplaceRequest = await this.createWorkOrderService.SubmitWebCatRepairReplace(
      //   this.authService.user.id,
      //   this.garmentSummary.employeeListing.customerCode,
      //   this.garmentSummary.employeeListing.employeeCode,
      //   this.garmentSummary.employeeListing.departmentCode,
      //   this.garmentSummary.employeeListing.stopCode,
      //   messages,
      //   this.userEmail
      // );

      // console.log(repairReplaceRequest);

      // if (repairReplaceRequest) {
      //   const data = {type: repairReplaceRequest.orderType, id: Number(repairReplaceRequest?.woId ?? 0), email: this.userEmail, token: repairReplaceRequest.sessionCode};
      //   // Send confimation email + text
      //   await Promise.all([
      //     this.emailService.sendOrderConfirmation(data),
      //     this.smsService.sendWebOrderSMS(Number(repairReplaceRequest?.woId ?? 0), repairReplaceRequest.sessionCode)
      //   ]);
      //   // Success popup
      //   this.toasterService.presentToast('primary', 'Thank you for your order! You will be getting a confimation email/text shortly.', 'Request Confirmed', 4000);
      //   this.navController.navigateRoot(['/mobile']);
      // }
    } catch (error) {
      console.log(error);
      this.toasterService.presentToast('danger', '', 'Repair Submitted Failed!', 2500);
    }
  }

  expandGarment(garment) {
    garment.expanded = !garment.expanded;
  }

  addGarment(garment, event) {
    if (event.target.checked) {
      this.selectedGarments.push(garment);
    }
    else {
      this.selectedGarments = this.selectedGarments.filter(g => g !== garment);
    }
  }

  reasonSelect(garment, event) {
    if(event.target.value === 'N/A'){
      //check if garment is already in selectedGarments
      if (!this.selectedGarments.includes(garment)) {
        return;
      } else{
        this.selectedGarments = this.selectedGarments.filter(g => g !== garment);
        garment.checked = false;
      }
    } else{
      //check if garment is already in selectedGarments
      if (!this.selectedGarments.includes(garment)) {
        garment.reason = event.target.value;
        garment.checked = true;
        this.selectedGarments.push(garment);
      } else{
        //find garment in selectedGarments and update reason
        this.selectedGarments.forEach(g => {
          if(g === garment){
            g.reason = event.target.value;
          }
        });
      }
    }
  }

  cancel(): void {
    this.navController.navigateRoot(['/mobile']);
  }

}