<ion-content class="invoice" scroll="false">
    <ng-container *ngIf="loaded">
        <app-header [title]="'Invoice Details'"></app-header>
        <div class="invoice-container">
            <div class="top-header-row">
                <div class="image-container">
                    <img src="../../../../assets/images/gallagher logo_h.png">
                </div>
                <div class="titles">
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-number-text">INVOICE #: </span>
                        </div>
                        <div>
                            <span class="invoice-number-text">{{invoiceDetails.invoiceCode}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Date:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.dateOfInvoice | date: "MM/dd/yyyy"}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Customer #:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.customer?.customerCode ?? 'Unknown'}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Stop #:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.stopCode}}</span>
                        </div>
                    </div>
                    <div class="header-line-item">
                        <div class="header-line-item-title">
                            <span class="invoice-info-text">Purchase Order #:</span>
                        </div>
                        <div>
                            <span class="invoice-info-text">{{invoiceDetails.poNum}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <ion-grid>
                <ion-row class="cust-info">
                    <ion-col>
                        <ion-row class="cust-info-1">
                            <ion-col size="auto" class="ion-no-padding cust-info-header-col">
                                <ion-row class="cust-title">
                                    <div>Invoice To:</div>
                                </ion-row>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <ion-row class="cust-gen-title">
                                    {{invoiceDetails.address.billingName}}
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.billingAddress1}} 
                                </ion-row>
                                <ion-row *ngIf="invoiceDetails.address.billingAddress2" class="cust-gen">
                                    {{invoiceDetails.address.billingAddress2}} 
                                </ion-row>
                                <ion-row *ngIf="invoiceDetails.address.billingAddress3" class="cust-gen">
                                    {{invoiceDetails.address.billingAddress3}} 
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.billingCity}}, {{invoiceDetails.address.billingState}} {{invoiceDetails.address.billingPostalCode}}
                                </ion-row>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col>
                        <ion-row class="cust-info-2">
                            <ion-col size="auto" class="ion-no-padding cust-info-header-col">
                                <ion-row class="cust-title">
                                    <div>Deliver To:</div>
                                </ion-row>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <ion-row class="cust-gen-title">
                                    {{invoiceDetails.address.deliveryName}}
                                </ion-row>
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.deliveryAddress1}}
                                </ion-row>
                                <ion-row *ngIf="invoiceDetails.address.deliveryAddress2" class="cust-gen">
                                    {{invoiceDetails.address.deliveryAddress2}}  
                                </ion-row>
                                <!-- <ion-row *ngIf="invoiceDetails.address.deliveryAddress3" class="cust-gen">
                                    {{invoiceDetails.address.deliveryAddress3}}  
                                </ion-row> -->
                                <ion-row class="cust-gen">
                                    {{invoiceDetails.address.deliveryCity}}, {{invoiceDetails.address.deliveryState}} {{invoiceDetails.address.deliveryPostalCode}}
                                </ion-row>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid>
                <ion-row class="top-row">
                    <ion-col class="double-b right-border" style="text-align: start;">
                        <ion-header style="text-align: start; padding: 4px;"  class="ion-no-border">Route Service Representative</ion-header>
                        <ion-row style="justify-content: start; padding: 4px; font-weight: bold;" size="4.5">
                            {{invoiceDetails.driver?.employee?.firstName}} {{invoiceDetails.driver?.employee?.lastName}} - {{invoiceDetails.driver?.employee?.workPhone}}
                        </ion-row>
                    </ion-col>
                    <ion-col class="double-b right-border" style="text-align: center;" size="1">
                        <ion-header style="text-align: center; padding: 4px;"  class="ion-no-border">Week</ion-header>
                        <ion-row style="justify-content: center; padding: 4px; font-weight: bold;">{{invoiceDetails.routeWeek}}</ion-row>
                    </ion-col>
                    <ion-col class="double-b right-border" style="text-align: center;" size="1">
                        <ion-header style="text-align: center; padding: 4px;" class="ion-no-border ">Day</ion-header>
                        <ion-row style="justify-content: center; padding: 4px; font-weight: bold;">{{getDayText(invoiceDetails.routeDay)}}</ion-row>
                    </ion-col>
                    <ion-col class="double-b right-border" style="text-align: center;" size="1">
                        <ion-header style="text-align: center; padding: 4px;" class="ion-no-border">Route</ion-header>
                        <ion-row style="justify-content: center; padding: 4px; font-weight: bold;">{{invoiceDetails.routeCode}}</ion-row>
                    </ion-col>
                    <ion-col class="double-b" style="text-align: right;" size="4.5">
                        <ion-header style="text-align: right; padding: 4px;"  class="ion-no-border">Route Service Supervisor</ion-header>
                        <ion-row style="justify-content: right; padding: 4px; font-weight: bold;">{{invoiceDetails.supervisor?.name}} - {{invoiceDetails.supervisor?.phone}}</ion-row>
                    </ion-col>
                </ion-row>

                <ion-row class="main-row" style="background-color: lightgrey;">
                    <ion-col size="1" class="double-b right-border">
                        <ion-header style="text-align: start; padding: 4px;" class="ion-no-border mobile-header">Line Num</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size="1">
                        <ion-header style="text-align: start; padding: 4px;" class="ion-no-border mobile-header">Wash Qty</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size="0.8">
                        <ion-header style="text-align: start; padding: 4px;" class="ion-no-border mobile-header">Garment Label ID</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size="2.5">
                        <ion-header style="text-align: start; padding: 4px;" class="ion-no-border mobile-header">Item or Employee Name</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size="2">
                        <ion-header style="text-align: start; padding: 4px;" class="ion-no-border mobile-header">Product</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size=".9">
                        <ion-header style="text-align: start; padding: 4px;" class="ion-no-border mobile-header">Size</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size=".9">
                        <ion-header style="text-align: end; padding: 4px;" class="ion-no-border mobile-header">Inven Qty</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size=".9">
                        <ion-header style="text-align: end; padding: 4px;" class="ion-no-border mobile-header">Charge Qty</ion-header>
                    </ion-col>
                    <ion-col class="double-b right-border" size="1">
                        <ion-header style="text-align: end; padding: 4px;" class="ion-no-border mobile-header">Unit Price</ion-header>
                    </ion-col>
                    <ion-col size="1">
                        <ion-header style="text-align: end; padding: 4px;" class="ion-no-border mobile-header">Invoice Amount</ion-header>
                    </ion-col>
                </ion-row>

                <ion-row class="main-row">
                   <ion-col class="ion-no-padding">
                        <ion-row *ngFor="let item of invoiceDetails.invoiceItems">
                            <ion-col size="1" class="double-b" >
                                <ion-row style="justify-content: start; padding: 4px;">{{item.lineNumber}}</ion-row>
                            </ion-col>
                            <ion-col size="1">
                                <ion-row style="justify-content: start; padding: 4px;">{{item.washQty}}</ion-row>
                            </ion-col>
                            <ion-col size="0.8">
                                <ion-row style="justify-content: start; padding: 4px;">{{item.stopCode}} {{item.employeeCode}}</ion-row>
                            </ion-col>
                            <ion-col size="2.5">
                                <ion-row style="justify-content: start; padding: 4px;">{{item.itemName}}</ion-row>
                            </ion-col>
                            <ion-col size="2">
                                <ion-row *ngIf="item.productType !== 2" style="justify-content: start; padding: 4px;">
                                    {{item.categoryDesc?.length > 0 ? (item.categoryDesc + ' - '): ''}}{{item.itemCode}}
                                </ion-row>
                                <ion-row *ngIf="item.productType === 2" style="justify-content: start; padding: 4px;">
                                    {{itemFreqMap.get(item.itemFreq)}}
                                </ion-row>
                            </ion-col>
                            <ion-col size=".9">
                                <ion-row style="justify-content: start; padding: 4px;">{{item.size1}}{{item.size1?.length > 0 ? '-' : ''}}{{item.size2}}</ion-row>
                            </ion-col>
                            <ion-col size=".9">
                                <ion-row style="justify-content: end; padding: 4px;">{{item.issueQty}}</ion-row>
                            </ion-col>
                            <ion-col size=".9">
                                <ion-row style="justify-content: end; padding: 4px;">{{item.chargeQty}}</ion-row>
                            </ion-col>
                            <ion-col size="1">
                                <ion-row style="justify-content: end; padding: 4px;">{{item.chargeAmt | currency: 'USD'}}</ion-row>
                            </ion-col>
                            <ion-col size="1">
                                <ion-row style="justify-content: end; padding: 4px;">{{item.billingAmount | currency: 'USD'}}</ion-row>
                            </ion-col>
                        </ion-row>
                   </ion-col>
                </ion-row>
                <ion-row class="total-row">
                    <ion-col size="9" class="thank-you">
                        <div style="text-align: center; height: 100%; display: flex; align-items: center; justify-content: center;">
                            Thank you for letting us serve you!
                        </div>
                    </ion-col>
                    <ion-col class="total-col">
                        <ion-col>
                            <ion-col class="total-subcol top-total-subcol">
                                <div class="total-label">
                                    <ion-header class="ion-no-border" class="print-border">Subtotal</ion-header>
                                </div>
                                <div class="subtotal-div" style="text-align: end; margin-right: 4px;">
                                    {{subTotal | currency: 'USD'}}
                                </div>
                            </ion-col>
                            <ion-col class="total-subcol">
                                <div class="total-label">
                                    <ion-header class="ion-no-border" >State Tax</ion-header>
                                </div>
                                <div style="text-align: end; margin-right: 4px;">
                                    {{invoiceDetails.tax1Amount | currency: 'USD'}}
                                </div>
                            </ion-col>
                        </ion-col>
                    </ion-col>
                </ion-row>
                <ion-row class="sig-row">
                    <ion-col class="double-b right-border" >
                        <ion-header class="ion-no-border">Customer Signature</ion-header>
                        <p>X</p>
                        <hr class="signature-line">
                    </ion-col>
                    <ion-col class="right-border" style="text-align: center;">
                        <ion-header class="ion-no-border">Terms</ion-header>
                        <div style="padding-top: 0.5rem;"><b>{{invoiceDetails.termName}}</b></div>
                    </ion-col>
                    <ion-col class="right-border" style="text-align: center;">
                        <ion-header class="ion-no-border">Due Date</ion-header>
                        <div style="padding-top: 0.5rem;"><b>{{invoiceDetails.dueDate | date: "MM/dd/yyyy"}}</b></div>
                    </ion-col>
                    <ion-col style="text-align: center;">
                        <ion-header class="ion-no-border">Invoice Total</ion-header>
                        <div style="padding-top: 0.5rem;"><b>{{invoiceDetails.amountDue | currency: 'USD'}}</b></div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <button></button>
        </div>

        <div class="footer">
            <ion-row>
                <ion-col>
                    <span class="bold-text margin-right">Gallagher Uniform</span>
                    <span>151 McQuiston Drive - Battle Creek, MI - 49037</span>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <span class="small-text">Phone: 269-965-5171, 800-468-7174 &nbsp; Fax: 269-965-2810 &nbsp; <a href="mailto:Gservice@gallagheruniform.com">E-mail: Gservice&#64;gallagheruniform.com</a></span>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <span class="bold-text">Don't forget that all invoices are available on G-Trak &#64; <a href="www.gallagheruniform.com">www.gallagheruniform.com</a></span>
                </ion-col>
            </ion-row>
        </div>

    </ng-container>
</ion-content>